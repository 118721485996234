import * as React from 'react'
import { Helmet } from 'react-helmet'
import LayoutService from '../../templates/LayoutService'
import { blogHeader, blogPostContainer, postContentBlock } from '../../styles/components/blog.module.scss'
import { pageContentMedia } from '../../styles/components/blocks.module.scss'
import { StaticImage } from 'gatsby-plugin-image'
import { imgBox } from '../../styles/components/boxes.module.scss'
import '../../styles/components/content.module.scss'
import ServicesList from '../../components/blocks/ServicesList'
import { Link } from 'gatsby'

const BlogPage = () => {
  return (
    <LayoutService>
      <Helmet>
        <title>The History of Beer Can Island (AKA - Pine Key) | Tampa Bay Boating Adventures</title>
      </Helmet>
      <main className='container'>
        <div className='row'>
          <div className='col-xxs-12'>
            <div className={blogHeader}>
              <div className={imgBox}>
                <StaticImage
                  alt="Boaters hanging out in the cool water around Beer Can Island in Florida."
                  src="../../images/blog/beer-can-island-boaters-in-water.jpg"
                />
              </div>
            </div>
            <div className={blogPostContainer}>
              <div className='row center'>
                <div className='col'>
                  <h1>The History of Beer Can Island (AKA - Pine Key)</h1>
                  <p>September 12, 2022</p>
                </div>
              </div>
              <div className='row'>
                <div className='col-xxs-12'>
                  <div className={postContentBlock}>
                    <h2>Tampa Bay Boating Adventures Destinations Include Beer Can Island</h2>
                    <h3>Explore the lesser-known history of Tampa Bay’s most popular party island!</h3>
                    <p>If you have been looking for things to do within the Tampa Bay region, you may have stumbled across the location of Beer Can Island. There are actually two islands within Tampa commonly known as Beer Can Island. One is within Longboat Key, close to Sarasota, while the other is right in the centre of Tampa Bay, around 2.7 miles northwest of the Manatee Center. It is the latter that is the most popular with those looking to explore the area. Beer Can Island is a popular spot for both tourists and locals alike to anchor their boats and spend some downtime. But why is it called Beer Can Island? Let’s take a look into the isle’s history:</p>
                    <div className={pageContentMedia}>
                      <div className={imgBox}>
                        <StaticImage
                          alt="Partiers playing volleyball on the beach at Beer Can Island in Florida."
                          src="../../images/blog/partiers-playing-volleyball-on-beach-at-beer-can-island-florida.jpg"
                        />
                      </div>
                    </div>
                    <h2>What Does Beer Can Island Look Like?</h2>
                    <p>Beer Can Island spans over 8 acres and is actually man-made. Originally, its purpose was to be a location where both sand and sediment were dumped. <strong>This is known as a spoil island and is used when the sea floor was being dug up or shaped for shipping channels, as a place to get rid of the excess sediment.</strong> For example, when the Apolla Beach Marina – which is only around 2.5 miles away was formed, much of the excess sand was dredged to Beer Can Island. As the sediment, debris and sand that make up the island were originally from the sea floor, it contains several different treasures including the fossils of animals from the past. Caretaker of the island, Bryan describes how even to this day, you can walk along the island’s white sand beaches and find fragments of fossils, turtle shells and shark teeth - life from the past captured in time and now used to create the landscape of the island as it is today.</p>
                    <hr />
                    <h2>How Did Beer Can Island Get Its Name?</h2>
                    <div><iframe width="100%" height="360" src="https://www.youtube.com/embed/aHPSqKzezns" title="Exploring Pine Key (Beer Can Island) And Fossils with Caretaker Bryan" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></div>
                    <p>The island was nicknamed ‘beer can’ island due to the fact that many people visit the island to party and drink some beers while watching the warm Florida sunset. In recent years, the owners have renamed the island Pine Key Island but the alcohol-related name still sticks.</p>
                    <hr />
                    <h2>How Can You Access Beer Can Island?</h2>
                    <p>As the location is an island, it can only be accessed via the water. <strong>The most common way to reach the island is by boat</strong> – with many local boat charters offering trips to the island, or stopping off there as part of a larger itinerary.</p>
                    <hr />
                    <h2>Can I Visit Beer Can Island?</h2>
                    <p>Absolutely – Beer can island is open to the public and can be visited for free. The best way to reach the island is by chartering a boat, such as one from Tampa Bay Boating Adventures, so that you can explore the local area. You may need a membership to visit officially, however, gaining one is both easy and free and can be accessed on the membership page for the island. The webpage also offers a number of different <a href="https://www.joinit.org/o/beer-can-island" target="_blank">memberships available</a> at a cost that offer a discount on the island’s facilities. The island has been known in the past to have some beach games, a rope slide and the opportunity to play sand volleyball, as well as take in the views and crack open a beer. If you’d like to explore Beer Can Island, our trips often run to this island. Give us a call at <a href="tel:8137428178">(813) 742-8178</a> or visit us online!</p>
                    <hr />
                    <h2>Select Your Party Boat Charter</h2>
                    <h3>See what all's included with our wide variety of party boat charter packages.</h3>
                  </div>
                </div>
              </div>
            </div>
            <ServicesList />
          </div>
        </div>
      </main>
    </LayoutService>
  )
}

export default BlogPage